.compliance-report{
   position: relative;

   &__menu{
    display: flex;
    position: absolute;
    top: 28px;
    right: -23px;
    z-index: 10;
    }

    &__cog-wrapper{
        margin-top: 15px;
        height: 40px;
        width: 50px;
        display: flex;
        border-radius: 5px 0 0 5px;
        align-items: center;
        background: #1B2E43;
        color: #fff;    
    }

    &__drawer-wrapper{
        border-radius: 4px 0px 0px 4px;
        padding: 19px 20px;
        background: #1B2E43;
        color: #fff;
        height: 550px;
        width: 260px;
    }

    &__drawer{
        & p{
            font-size: 18px;
            margin: 0;
            font-weight: 700;
        }

        & span{
            padding: 0;
            height: 32px;
            width: 16px;

            & svg{
                color: #fff;
            }            
        }

        & h6{
            font-size: 17px;
            font-weight: 400;
        }

        & button{
            background: rgb(73,161,223);
            height: 34px;
            width: 100px;
            text-align: center;
            padding: 4px;

            &:hover{
                background: #2F8FFF;;
            }
        }
    }

    &__widget-list{
        padding: 15px 0;
    }

    &__widget-list-item{
        display: flex;
        gap: 10px;
        padding: 0.2rem 0;
    }



    //Toolbar
    &__toolbar{
        display: flex;
        flex-direction: row;
        height: 40.5px;
        gap: 0.5rem;
        margin-bottom: 1rem;

        & button {
            font-size: 0.9rem;
            border-radius: 4px;
            height: 40.5px;
            width: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .react-datepicker__wrapper{
            height: 40.5px;
        }

        & .react-datepicker__input-container{
            height: 100%;
            border-radius: 2.5px !important;
            border: 1px solid #c5cfda;
            color: #385980;
        }

        & .react-datepicker__input-container input{
            height: 100%;
            border: 1px solid #fff;
            padding-left: 0.5rem;
        }

        & .react-datepicker__navigation--previous{
            width: 20px;
            height: 20px;
        }

        & .react-datepicker__navigation--next{
            width: 20px;
            height: 20px;
        }
   }

   &__toolbar-client-select{
        & .toolbar-client-select__control{
            min-height: 30px;
            border-radius: 4px !important;
            max-width: 30ch;
        }

        & .toolbar-client-select__input{
            color: #385980;
        } 
   }

   // Table Container
   &__table-container{
        th, td{
            font-family: "ProximaNovaRegular";
            color: #385980;
            border-right: 1px solid #E0E0E0;
        }

        & tBody tr:last-child(){
            background: rgb(235,238,243);
        }
    }

    // Total Data row
    &__total-data{
        td{
            font-weight: bold;
            background: rgb(235,238,243);
            border: 2px solid #888888;
        }

        & .sup-cell{
            position: relative;

            & sup{
                position: absolute;
                top: 20%;
                left: 5%;
                font-weight: 100;
            }
        }
    }

    // Percent Variance
    &__percent-variance{
        td{
            border: 2px solid #888888;
        }
    }
}




//media Queries -------------------------------------------------------------------
@media (max-width: 1024px){
    .compliance-report{
        padding-left: 1rem;
        padding-right: 1rem;

        &__menu{
            top: -76px;
            right: 0;
        }
    }
}