.inspection-certification {
  // need to verify if this is the correct shade of grey
  margin-bottom: 3em;

  .insp-form-row {
    // align with layout body
    margin: 0;
    position: relative;

    .form-group {
      display: flex;
      flex-flow: column nowrap;
      padding-left: 0;
      flex: 1 1 auto;

      .form-control--emailMessage {
        margin-top: 0.5em;
        border: none;
        text-align: left;
        padding-left: 0;

        @media (max-width: $breakpoint-xs) {
          margin-top: 1em;
          border: none;
          text-align: center;
          padding: 0 1em;
        }
      }
    }

    .signature-options {
      text-align: right;
      justify-content: flex-end;
      text-align: right;

      @include breakpoint("md") {
        position: absolute;
        left: 100%;
        top: 70%;
        width: 200px;
      }
    }
  }

  textarea {
    width: 100%;
  }

  hr {
    margin: 2em 0;
    border: 0;
    border-top: $border;
  }

  p {
    color: $chambray;
    font-size: 0.85em;
    font-weight: normal;
  }

  h4 {
    margin-bottom: 0.25rem;
    color: $baliHai;
    font-family: $font-family-bold;
  }

  .error {
    margin: 0;
    padding: 0;
    color: $bitterSweet;
  }

  .additional-notes {
    width: 100%;

    .form-text-info {
      margin-bottom: 0.5rem;
    }
  }

  .edit-button {
    align-self: center;
  }

  .attestation-edit-mode {
    margin-bottom: 2em;
  }
}

.electronic-signature-wrapper {
  height: 100%;
  // align with other inputs that contain labels
  margin: 0 0 1.4em;

  &>p {
    text-align: right;
  }

  .signature-error {
    text-align: left;
  }

  // element has .pure-u-1.pure-u-md-1-2, so add top margin above lg+
  @include breakpoint("md") {
    margin-top: 1.4em;
  }
}

.electronic-signature {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
  padding: 0.5em 1em;
  border: $border;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  .label-wrapper {
    padding: 0 1em;
    font-size: 0.8em;
    display: flex;
    flex-flow: column nowrap;
  }

  /* borrowed from https://hackernoon.com/hacking-custom-checkboxes-and-radios-5d48230440d */
  // hide native checkbox off the screen
  input[type="checkbox"] {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  /* Styles for the basic appearance of the custom checkbox */
  input[type="checkbox"]~.pseudo-checkbox {
    width: 1rem;
    height: 1rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: $border;
    cursor: pointer;
    padding: 0.5em;
  }

  // hover state
  input[type="checkbox"]:hover~.pseudo-checkbox {
    border-color: #4a4a4a;

    &.disabled {
      cursor: not-allowed;
      border-color: $kashmir;
    }
  }

  // focus state
  input[type="checkbox"]:focus~.pseudo-checkbox {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);

    &.disabled {
      border-color: $kashmir;
    }
  }

  // checked state
  input[type="checkbox"]:checked~.pseudo-checkbox {
    border-color: $primary;
    background: $primary url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
    background-size: 75%;

    &.disabled {
      border-color: $kashmir;
      background: $kashmir url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
      cursor: not-allowed;
    }
  }
}