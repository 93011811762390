.inspections-due{
    //header
    &__header{
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: 1fr 1fr;
        grid-template-areas: 
        "title title title title title title title . . . . ellipsis"
        "bars bars bars bars bars bars bars bars bars bars bars bars";
        margin-bottom: 10px;
        grid-row-gap: 6px;

        & span{
            border: 1px solid rgb(163, 163, 163);
            padding: 0.9rem;
            height: 32px;
            width: 37px;
            border-radius: 4px;
            display: flex;
            place-items: center;
            color: rgb(80,101,126);


            &:hover{
                cursor: pointer;
            }
        }
    }

    &__title{
        grid-area: title;
        color: #284260;
        font-size: 22px;
        font-weight: 500;
    }

    &__ellipsis-button{
        grid-area: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__bars{
        grid-area: bars;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;

        & section{
            display: flex;
            align-items: center;
            background: #fff;
            height: 32px;
            min-width: 150px;
            color: #284260;
            // flex-basis: 45%;
            font-size: 15px;
            line-height: 1.1;
        }

        & input{
            padding: 0 1rem;
            border: 1px solid rgb(163, 163, 163);
            height: 32px;
            border-radius: 4px;
            color: rgb(80,101,126);
            // flex-basis: 50%;
        }
    }

    &__tools-options{
        position: absolute;
        height: 5.5rem;
        width: 13rem;
        background: #fff;
        color: #84909f;
        z-index: 10;
        top: 49px;
        right: -2px;
        border-radius: 4px;
        border: 0.1px solid rgb(221, 221, 221);
        box-shadow: 6px -1px 8px rgb(224, 224, 224);
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem 0;
        padding: 0 3rem 0 2rem;

        & h4{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 20px;

            & p{
                position: relative;
                top: 2px;
                flex-basis: 80%;
            }

            &:hover{
                cursor: pointer;
            }
        }
    }

    //body
    &__body{
        border: 0.1px solid rgb(221, 221, 221);
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    //content
    &__content{
        @include hide-scrollbar;
        border-radius: 5px 5px 0 0;
        height: 400px;

        & .inspection-due__list-view{
            min-width: 600px;
        }
    }

    &__table-head{
        background: rgb(255,255,255);
        color: #385980;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid rgb(196, 195, 195);
        position: sticky;
        top: 0;
        gap: 1rem;
        height: 60px;

        & > h2 {
            flex-basis: 10%;
        }

        & h3 {
            font-size: 15px;
            font-weight: 600;
            flex-basis: 20%;
            text-align: left;
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
            gap: 0.4rem;
        }

        & h3:first-child(){
            margin-left: 1rem;
        }

        & h3:nth-child(n+3){
            text-align: center;
            justify-content: center;
        }
    }

    &__table-body{
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;

        & li {
            font-family: "ProximaNovaRegular";
            font-size: 15px;
            color: #6D747E;
            text-align: left;
            flex-basis: 20%;
            margin: 0.5rem 0 0 0;            
            height: 32px;
            line-height: 1.1;
            display: flex;
            align-items: center;
        }

        & li:first-child(){
            margin-left: 1rem;
        }

        & li:nth-child(n+3){
            text-align: center;
            justify-content: center;
        }

        & li:not(:nth-child(n+3)){
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: flex;
            align-items: center;
        }

        & a{
            text-decoration: none;
        }

        & span{
            display: inline-block;
            padding: 0.5rem 0.5rem;
            color: white;
            font-family: "ProximaNovaBold";
            font-size: 0.8rem;
            border-radius: 4px;
            width: 125px;
            height: 30px;
        }
    }

    //footer
    &__footer{
        border-top: 1px solid rgb(196, 195, 195);
        border-radius: 0 0 5px 5px;
        padding: 0.5rem 2rem 0.5rem 4rem;
        display: flex;
        justify-content: center;
        height: 60px;

        & span:nth-child(1){
            padding: 0.7em 1em;
            color: #284260;
            border: 0;
            font-family: "ProximaNovaBold";
            font-size: 1rem;
            border-radius: 4px;

            &:hover{
                cursor: pointer;
            }
        }
    }
}


// Classes to dynamically handle CTA Color
.list-view-show-project-color{
    background: #7BB8FF;

    &:hover{
      cursor: pointer;
      background-color: #2F8FFF;
    }
}

.list-view-start-inspection-color{
    background: #4ADE86;

    &:hover{
        cursor: pointer;
        background-color: #3CB26C;
    }
}

.list-view-past-due-color{
    background: #B33A3A;

    &:hover{
        cursor: pointer;
        background-color: #da4545;
    }
}


// Calendar View -------------------------------------------------------------
.inspections-due{
    &__table-calendar-head{
        background: rgb(255,255,255);
        color: #385980;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(196, 195, 195);
        height: 60px;
    }

    &__table-calendar-body{
        height: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        
        & > div{
            height: 350px;
            border-right: 1px solid rgb(206, 206, 206);
        }

        & > div > section:nth-child(1){
            background-color: blue;
            height: 50px;
        }

        & > div > section:nth-child(2){
            background-color: red;
        }
    }
}



//media query
@media (max-width: 899px){
    .inspections-due{
        &__footer span{
            visibility: hidden;
        }
    }
}