.team-management {
  //header
  &__header {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr 1fr;
    grid-template-areas:
      'title title title title title title title . . . . ellipsis'
      'bars bars bars bars bars bars bars bars bars bars bars bars';
    margin-bottom: 10px;
    grid-row-gap: 6px;

    & span {
      border: 1px solid rgb(163, 163, 163);
      padding: 0.9rem;
      height: 32px;
      width: 37px;
      border-radius: 4px;
      display: flex;
      place-items: center;
      color: rgb(80, 101, 126);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__title {
    grid-area: title;
    color: #284260;
    font-size: 22px;
    font-weight: 500;
  }

  &__ellipsis-button {
    grid-area: ellipsis;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__bars {
    grid-area: bars;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    & select {
      padding: 0 0.5rem;
      border: 1px solid rgb(163, 163, 163);
      background: #fff;
      height: 32px;
      min-width: 150px;
      border-radius: 4px;
      color: rgb(80, 101, 126);
      flex-basis: 35%;
    }

    & input {
      padding: 0 1rem;
      border: 1px solid rgb(163, 163, 163);
      height: 32px;
      border-radius: 4px;
      color: rgb(80, 101, 126);
      flex-basis: 60%;
    }
  }

  &__ellipsis-options {
    position: absolute;
    height: 4.5rem;
    width: 13rem;
    background: #fff;
    color: #84909f;
    z-index: 10;
    top: 49px;
    right: -2px;
    border-radius: 4px;
    border: 0.1px solid rgb(221, 221, 221);
    box-shadow: 6px -1px 8px rgb(224, 224, 224);
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem 0;
    padding: 0 3rem 0 2rem;

    & h4 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 20px;

      & p {
        position: relative;
        top: 2px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    & h4:nth-child(2) {
      & p {
        position: relative;
        left: -6px;
      }
    }
  }

  //body
  &__body {
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  //content
  &__content {
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    padding: 0 0 1rem 0;
    height: 400px;
    overflow-y: auto;
    position: relative;
  }

  &__table-head {
    padding: 0 10px 0 10%;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    color: #385980;
    display: flex;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    z-index: 9;
    top: 0;
    height: 60px;

    .header-text {
      color: #385980;
      font-weight: 600;
      position: sticky;
      left: 50px;
      font-size: 15px;
    }
    .reassign-now-btn {
      width: 105px;
      text-transform: none;
      font-weight: bold;
      color: #FFF;
      background: #4ADE86;
      border-radius: 4px;
      box-shadow: none !important;
      font-size: 12px;
    }

    .reassign-now-btn:hover {
      background: #3cb26c;
    }
  }

  //footer
  &__footer {
    border-top: 1px solid rgb(196, 195, 195);
    border-radius: 0 0 5px 5px;
    padding: 0.5rem 2rem 0.5rem 4rem;
    display: flex;
    justify-content: center;
    height: 60px;

    & span:nth-child(1) {
      padding: 0.7em 1em;
      color: #284260;
      border: 0;
      font-family: 'ProximaNovaBold';
      font-size: 1rem;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .checkboxDeactive {
    color: white;
    width: 1em;
    background-color: #fff;
    border: 1px solid #dcdee7;
  }

  .checkboxActive {
    background-color: #7bb8ff;
    color: #fff;
    border: 1px solid #7bb8ff;
    width: 1em;
  }

  .collapseIcon {
    color: #7bb8ff;
  }
  .collapseIcon:hover {
    color: #2f8fff;
  }

  .view-project-btn {
    text-transform: none;
    background-color: #7bb8ff;
    color: white;
    font-family: 'ProximaNovaBold';
    border-radius: 4px;
    box-shadow: none !important;
    font-size: 12px;

    & a {
      text-decoration: none;
      color: #fff;
    }
  }

  .view-project-btn:hover {
    background: #2f8fff;
  }

  .Table-Cell-Text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .MuiTypography-root{
    font-family: 'ProximaNovaRegular' !important;
  }
}



//media query
@media (max-width: 899px){
  .team-management{
      &__footer span{
          visibility: hidden;
      }
  }
}